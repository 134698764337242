// @ts-ignore
import { divIcon, icon } from 'leaflet'

/**
 * @function getProposalColor
 * @param {object} offer
 * @returns {string} color
 */
function getProposalColor (offer) {
  let color = ''
  // @ts-ignore
  switch (offer.proposal.status) {
    case 'pending':
      color = 'blue'
      break
    case 'accepted':
      color = 'teal'
      break
    case 'expired': case 'cancelled': case 'revoked': case 'refused':
      color = 'danger'
      break
  }

  return color
}

export const fitBoundsOptions = {
  padding: [32, 32],
  duration: 1.2,
  animated: true
}

export const icons = {
  /**
   * Creates a cluster div icon with the cluster child count
   * @function icons.cluster
   * @param {object} cluster
   * @returns {object} divIcon
   */
  cluster: (cluster) => {
    return divIcon({
      className: 'ctk-cluster-icon',
      // @ts-ignore
      html: `<b>${cluster.getChildCount()}</b>`
    })
  },
  /**
   * Create an icon with a text content & the proposal class or not
   * @function icons.item
   * @param {string} text - Text to set in the marker
   * @param {boolean} isProposal - Specific if the offer has a proposal
   * @param {any?} offer
   * @returns {object} divIcon
   */
  item: (text, isProposal, offer = null) => divIcon({
    className: `ctk-single-marker-icon tw-flex tw-justify-center ${isProposal ? `icon-proposal ${offer ? `icon-color-${getProposalColor(offer)}` : ''}` : ''}`,
    html: `${isProposal ? '<i class="ctk-font icon-ctk-bid-2"></i>' : ''}<span>${text}</span>`
  }),
  /**
   * Create a pickup icon
   * @function icons.pickup
   * @param {string} state
   * @returns {object} icon
   */
  pickup: (state) => {
    const name = state !== 'scheduled' ? `${state}_pickup` : 'scheduled'

    return icon({
      iconUrl: require(`@/assets/img/icons/map/${name}.svg`),
      iconSize: [25, 35],
      iconAnchor: [11, 31],
      popupAnchor: [4, -25]
    })
  },
  /**
   * Create a delivery icon
   * @function icons.delivery
   * @param {string} state
   * @returns {object} icon
   */
  delivery: (state) => {
    const name = state !== 'scheduled' ? `${state}_delivery` : 'scheduled'

    return icon({
      iconUrl: require(`@/assets/img/icons/map/${name}.svg`),
      iconSize: [25, 35],
      iconAnchor: [11, 31],
      popupAnchor: [4, -25]
    })
  },
  /**
   * Create a disabled icon
   * @function icons.disabled
   * @param {string} state
   * @returns {object} icon
   */
  disabled: () => {
    return icon({
      // @ts-ignore
      iconUrl: require('@/assets/img/icons/map/disabled.svg'),
      iconSize: [25, 35],
      iconAnchor: [11, 31],
      popupAnchor: [4, -25]
    })
  },
  /**
   * Create a driver icon
   * @function icons.driver
   * @returns {object} icon
   */
  driver: () => {
    return icon({
      // @ts-ignore
      iconUrl: require('@/assets/img/markerdriver.png'),
      iconSize: [21, 31],
      iconAnchor: [10.5, 31],
      popupAnchor: [4, -25]
    })
  }
}
